var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProjectPageTemplate',{staticStyle:{"position":"relative","height":"100%","width":"100%"},attrs:{"loading":false,"hideHeader":_vm.latest == null || _vm.latest.length == 0,"error":false,"title":_vm.$t(((_vm.$route.meta.i18n) + ".title"))},scopedSlots:_vm._u([{key:"header-action",fn:function(){return [_c('TextInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.latest != null && _vm.latest.length > 2),expression:"latest != null && latest.length > 2"}],staticClass:"mr-2",staticStyle:{"width":"300px"},attrs:{"clearable":"","icon":"mdi-magnify","value":_vm.searchValue,"placeholder":((_vm.$t('helper.text.search')) + "...")},on:{"change":function($event){_vm.searchValue = $event}}})]},proxy:true},{key:"content",fn:function(){return [(_vm.$store.getters.noDevices != true && _vm.filteredLatest.length > 0)?_c('div',{staticClass:"pb-3",staticStyle:{"height":"100%","position":"relative","overflow-x":"hidden","overflow-y":"auto"}},_vm._l((_vm.filteredLatest),function(preset,index){return _c('div',{key:index},[_c('div',{staticClass:"embed-card",style:((_vm.loadingSwitch == null && preset.isLatestImagePublic == true) ||
            (_vm.loadingSwitch !=
              ("" + index + (preset.deviceId) + (preset.presetId)) &&
              preset.isLatestImagePublic == true) ||
            (_vm.loadingSwitch ==
              ("" + index + (preset.deviceId) + (preset.presetId)) &&
              preset.isLatestImagePublic == true)
              ? 'background-color: #E0E0E0; border: 2px solid var(--v-primary-base)'
              : '')},[_c('v-row',{staticClass:"ma-0 ml-4",staticStyle:{"height":"150px"},attrs:{"align":"center"}},[_c('div',{staticStyle:{"border-radius":"10px","aspect-ratio":"16/9","height":"123px","background-color":"#b0b0b0"}},[_c('img',{staticStyle:{"border-radius":"10px","aspect-ratio":"16/9"},attrs:{"height":"123","src":preset.thumbnail.downloadUrl}})]),_c('div',{staticStyle:{"height":"135px","width":"calc(100% - 230px)"}},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('b',{staticClass:"ml-4",staticStyle:{"font-size":"17px"}},[_vm._v(_vm._s(_vm.$helper.formatPresetName(preset.presetId)))]),(
                    _vm.loadingSwitch ==
                    ("" + index + (preset.deviceId) + (preset.presetId))
                  )?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","width":"2","size":"16","color":"primary"}}):_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(preset.isLatestImagePublic),expression:"preset.isLatestImagePublic"}],staticClass:"primary pa-1 ml-2",staticStyle:{"border-radius":"50%","opacity":"0.75"},attrs:{"size":"17","color":"white"}},[_vm._v("mdi-earth")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"disabled":_vm.isOwner,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-switch',{key:_vm.resetSwitch,staticClass:"mb-2 mr-2",staticStyle:{"margin-top":"5px"},style:(!_vm.isOwner ||
                          _vm.loadingSwitch ==
                            ("" + index + (preset.deviceId) + (preset.presetId))
                            ? 'pointer-events: none; cursor: progress!important; opacity: .6'
                            : ''),attrs:{"disabled":!_vm.isOwner,"id":("" + index + (preset.deviceId) + (preset.presetId)),"ripple":false,"hide-details":"","small":""},on:{"change":function($event){return _vm.toggleSwitch(
                            $event,
                            preset.deviceId,
                            preset.presetId,
                            index
                          )}},model:{value:(preset.isLatestImagePublic),callback:function ($$v) {_vm.$set(preset, "isLatestImagePublic", $$v)},expression:"preset.isLatestImagePublic"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tools.project.settings.embed.disabledSwitch')))])])],1),(
                  (_vm.loadingSwitch == null &&
                    preset.isLatestImagePublic == true) ||
                  (_vm.loadingSwitch !=
                    ("" + index + (preset.deviceId) + (preset.presetId)) &&
                    preset.isLatestImagePublic == true) ||
                  (_vm.loadingSwitch ==
                    ("" + index + (preset.deviceId) + (preset.presetId)) &&
                    preset.isLatestImagePublic == true)
                )?_c('v-row',{staticClass:"ma-0",staticStyle:{"margin-top":"23px !important"},attrs:{"align":"center"}},[_c('CopyField',{staticClass:"ml-4",staticStyle:{"border":"1px solid #a0a0a0","max-width":"450px","border-radius":"20px"},attrs:{"prefix":"URL","link":_vm.dynamicShareUrl(preset.deviceId, preset.presetId),"loading":false}}),_c('IconButton',{staticClass:"ml-3",staticStyle:{"border":"1px solid #a0a0a0","border-radius":"50%"},attrs:{"tooltip":_vm.$t('tools.project.settings.embed.copyAsiFrame'),"tooltipPosition":"r","icon":"mdi-code-tags"},on:{"click":function($event){_vm.$helper.copy2Clipboard(
                      ("<iframe src=\"" + (_vm.$share.embedLink(
                        ("link=" + (_vm.dynamicShareUrl(
                          preset.deviceId,
                          preset.presetId
                        ))),
                        _vm.$route.params.lang
                      )) + "\" width=\"100%\" style=\"aspect-ratio: 16/9\">")
                    )}}})],1):_c('div',{staticClass:"ml-4 grey--text font-weight-normal mt-11",staticStyle:{"font-size":"14px","margin-top":"21px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.settings.embed.notPublic'))+" ")]),_c('div',{staticClass:"ml-4 mt-1 grey--text",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('helper.text.lastRefresh'))+": "),_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$helper.formatDate(preset.imageTime)))])])],1)])],1)])}),0):_c('div',[_c('v-row',{staticClass:"ml-5 mt-3 grey--text",staticStyle:{"font-size":"14px"},attrs:{"align":"center","justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.settings.embed.noDataFound')))])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }