<template>

    <v-row class="ma-0 mt-0" align="center">

        <div :style="(link == null && !loading) ? 'pointer-events: none!important; cursor: default!important' : ''"
            style="position: relative; cursor: pointer!important; width: calc(100% - 0px)" v-on:click="copyText">
            <div style="height: 100%; width: 100%; position: absolute; background-color: transparent; z-index: 10;">
            </div>
            <TextInput :prefix="prefix" :disabled="link == null && !loading" :id="`text-field-${this.link}`"
                :placeholder="(link == null && !loading) ? $t('tools.project.share.input.error') : $t('tools.project.share.input.loading')"
                :value="link || ''" readonly>
                <template v-slot:append>
                    <div style="width: 30px;"></div>
                    <div style="position: absolute; width: 25px; margin-bottom: 5px;">
                        <v-progress-circular class="ml-3" v-show="loading" indeterminate width="2" size="20"
                            color="primary"></v-progress-circular>
                        <IconButton v-if="!copied" v-on:click="copyText" v-show="!loading" class="ml-2"
                            style="top: -3px; position: absolute" dense icon="mdi-content-copy" />
                        <IconButton v-else v-show="!loading" class="ml-2" style="top: -3px; position: absolute" dense
                            icon="mdi-check" />
                    </div>

                </template>
            </TextInput>


        </div>
    </v-row>

</template>

<script>
import IconButton from '../button/IconButton.vue';
import TextInput from './TextInput.vue';
export default {
    props: {
        link: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false,
        },
        prefix: {
            type: String, 
            default: ''
        }
    },
    data() {
        return {
            copied: false,
        };
    },
    watch: {
        loading() {
                this.copied = false;
           
        }
    },
    methods: {
        copyText() {

            document.getElementById(`text-field-${this.link}`).select()
            this.$helper.copy2Clipboard(this.link);
            this.copied = true;

        },
    },
    components: { IconButton, TextInput }
};
</script>
