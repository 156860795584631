<template>
  <ProjectPageTemplate
  style="position: relative; height: 100%; width: 100%;"

    :loading="false"
    :hideHeader="latest == null || latest.length == 0"
    :error="false"
    :title="$t(`${$route.meta.i18n}.title`)"
  >
    <template v-slot:header-action>
      <TextInput
        class="mr-2"
        v-show="latest != null && latest.length > 2"
        style="width: 300px"
        clearable
        icon="mdi-magnify"
        :value="searchValue"
        v-on:change="searchValue = $event"
        :placeholder="`${$t('helper.text.search')}...`"
      />
    </template>

    <template v-slot:content>
      <div
        v-if="$store.getters.noDevices != true && filteredLatest.length > 0"
        class="pb-3"
        style="
          height: 100%;
          position: relative;
          overflow-x: hidden;
          overflow-y: auto;
        "
      >
        <div v-for="(preset, index) in filteredLatest" :key="index">
          <div
            class="embed-card"
            :style="
              (loadingSwitch == null && preset.isLatestImagePublic == true) ||
              (loadingSwitch !=
                `${index}${preset.deviceId}${preset.presetId}` &&
                preset.isLatestImagePublic == true) ||
              (loadingSwitch ==
                `${index}${preset.deviceId}${preset.presetId}` &&
                preset.isLatestImagePublic == true)
                ? 'background-color: #E0E0E0; border: 2px solid var(--v-primary-base)'
                : ''
            "
          >
            <v-row class="ma-0 ml-4" align="center" style="height: 150px">
              <div
                style="
                  border-radius: 10px;
                  aspect-ratio: 16/9;
                  height: 123px;
                  background-color: #b0b0b0;
                "
              >
                <img
                  height="123"
                  style="border-radius: 10px; aspect-ratio: 16/9"
                  :src="preset.thumbnail.downloadUrl"
                />
              </div>

              <div style="height: 135px; width: calc(100% - 230px)">
                <v-row class="ma-0" align="center">
                  <b class="ml-4" style="font-size: 17px">{{
                    $helper.formatPresetName(preset.presetId)
                  }}</b>
                  <v-progress-circular
                    v-if="
                      loadingSwitch ==
                      `${index}${preset.deviceId}${preset.presetId}`
                    "
                    indeterminate
                    width="2"
                    size="16"
                    class="ml-2"
                    color="primary"
                  ></v-progress-circular>
                  <v-icon
                    v-else
                    size="17"
                    v-show="preset.isLatestImagePublic"
                    class="primary pa-1 ml-2"
                    style="border-radius: 50%; opacity: 0.75"
                    color="white"
                    >mdi-earth</v-icon
                  >

                  <v-spacer />

                  <v-tooltip :disabled="isOwner" left>
                    <template v-slot:activator="{on, attrs}">
                      <span v-bind="attrs" v-on="on">
                        <v-switch
                          :disabled="!isOwner"
                          :id="`${index}${preset.deviceId}${preset.presetId}`"
                          :key="resetSwitch"
                          v-model="preset.isLatestImagePublic"
                          :style="
                            !isOwner ||
                            loadingSwitch ==
                              `${index}${preset.deviceId}${preset.presetId}`
                              ? 'pointer-events: none; cursor: progress!important; opacity: .6'
                              : ''
                          "
                          v-on:change="
                            toggleSwitch(
                              $event,
                              preset.deviceId,
                              preset.presetId,
                              index,
                            )
                          "
                          :ripple="false"
                          hide-details
                          small
                          style="margin-top: 5px"
                          class="mb-2 mr-2"
                        />
                      </span>
                    </template>
                    <span>{{
                      $t('tools.project.settings.embed.disabledSwitch')
                    }}</span>
                  </v-tooltip>
                </v-row>

                <v-row
                  v-if="
                    (loadingSwitch == null &&
                      preset.isLatestImagePublic == true) ||
                    (loadingSwitch !=
                      `${index}${preset.deviceId}${preset.presetId}` &&
                      preset.isLatestImagePublic == true) ||
                    (loadingSwitch ==
                      `${index}${preset.deviceId}${preset.presetId}` &&
                      preset.isLatestImagePublic == true)
                  "
                  class="ma-0"
                  align="center"
                  style="margin-top: 23px !important"
                >
                  <CopyField
                    prefix="URL"
                    style="
                      border: 1px solid #a0a0a0;
                      max-width: 450px;
                      border-radius: 20px;
                    "
                    class="ml-4"
                    :link="dynamicShareUrl(preset.deviceId, preset.presetId)"
                    :loading="false"
                  />
                  <IconButton
                    :tooltip="$t('tools.project.settings.embed.copyAsiFrame')"
                    tooltipPosition="r"
                    v-on:click="
                      $helper.copy2Clipboard(
                        `<iframe src=&quot;${$share.embedLink(
                          `link=${dynamicShareUrl(
                            preset.deviceId,
                            preset.presetId,
                          )}`,
                          $route.params.lang,
                        )}&quot; width=&quot;100%&quot; style=&quot;aspect-ratio: 16/9&quot;>`,
                      )
                    "
                    class="ml-3"
                    style="border: 1px solid #a0a0a0; border-radius: 50%"
                    icon="mdi-code-tags"
                  />
                </v-row>
                <div
                  v-else
                  class="ml-4 grey--text font-weight-normal mt-11"
                  style="font-size: 14px; margin-top: 21px"
                >
                  {{ $t('tools.project.settings.embed.notPublic') }}
                </div>
                <div class="ml-4 mt-1 grey--text" style="font-size: 14px">
                  {{ $t('helper.text.lastRefresh') }}:
                  <b class="ml-1">{{ $helper.formatDate(preset.imageTime) }}</b>
                </div>
              </div>
            </v-row>
          </div>
        </div>
      </div>
      <div v-else>
        <v-row
          class="ml-5 mt-3 grey--text"
          align="center"
          justify="center"
          style="font-size: 14px"
        >
          {{ $t('tools.project.settings.embed.noDataFound') }}</v-row
        >
      </div>
    </template>
  </ProjectPageTemplate>
</template>

<script>
import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';
import CopyField from '../../../../../../ui-components/src/components/text/CopyField.vue';
import IconButton from '@components/button/IconButton.vue';
import {config} from '@/server.config.js';
import TextInput from '../../../../../../ui-components/src/components/text/TextInput.vue';

export default {
  components: {
    // TextButton,
    ProjectPageTemplate,
    CopyField,
    IconButton,
    TextInput,
  },

  data() {
    return {
      config,
      loadingSwitch: null,
      resetSwitch: false,
      searchValue: '',
    };
  },

  computed: {
    isOwner() {
      return this.$auth.isProjectOwner();
    },
    filteredLatest() {
      return this.latest.filter((preset) =>
        preset.presetId.includes(this.searchValue),
      );
    },
    latest() {
      if (this.$store.getters.latest == null) return [];
      return this.$store.getters.latest.data.map((image) =>
        image.isLatestImagePublic == null
          ? {...image, isLatestImagePublic: false}
          : {...image},
      );
    },
  },

  watch: {
    latest: {
      handler() {
        this.resetSwitch != this.resetSwitch;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async toggleSwitch(event, deviceId, presetId, index) {
      if (this.loadingSwitch != null) return;
      this.loadingSwitch = `${index}${deviceId}${presetId}`;

      var resp = await this.$api.project.publicityStatus(
        this.$route.params.projectId,
        deviceId,
        presetId,
        event,
      );

      if (resp == null || resp.success == false) {
        this.$nextTick(() => {
          document.getElementById(`${index}${deviceId}${presetId}`).click();
        });

        setTimeout(() => {
          this.$toast.error(
            this.$t('tools.project.settings.embed.toasts.error'),
          );
          this.loadingSwitch = null;
          this.resetSwitch = !this.resetSwitch;
        }, 200);
      } else {
        await this.$api.project.latestImages(this.$route.params.projectId);
        this.$toast.info(
          this.$t('tools.project.settings.embed.toasts.success'),
        );

        this.loadingSwitch = null;
      }
    },
    dynamicShareUrl(deviceId, presetId) {
      return `https://storage.googleapis.com/${
        this.config[process.env.VUE_APP_ENV].publicStorage
      }/${this.$route.params.projectId}/${deviceId}/${presetId}/latest.jpg`;
    },
  },
};
</script>

<style scoped>
.embed-card {
  height: 150px;
  margin: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 7px;
  border: 2px solid #e0e0e0;
  position: relative;
  border-radius: 10px;
  background-color: #f0f0f0;
}
</style>
